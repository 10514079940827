'use strict';

angular.module('informaApp')
    .service('DurationTypeEnum', function () {
        var types = ['Box Plot', 'Mean', 'Median'];
        
        return {
            boxPlot: types[0],
            mean: types[1],
            median: types[2],

            types: types
        }
    });